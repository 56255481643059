// services/versionService.ts
export const getLatestVersion = async (): Promise<string | null> => {
    try {
      const currentVersion = '1.0.1';
      return currentVersion; // Assuming the API returns { "version": "1.0.1" }
    } catch (error) {
      console.error('Error fetching the latest version:', error);
      return null;
    }
  };
  