import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonLabel,
    IonModal,
    IonPage,
    IonRow,
    useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
// import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import apiConfig from "../../apiConfig";
import fingerprintService from "../../fingerprint.service";
import "./Getstarted.css";
import { PushNotifications } from '@capacitor/push-notifications';

import { Network } from '@capacitor/network';

import messaging from '../../Firebase';
import { getToken, isSupported } from 'firebase/messaging';

const Getstarted: React.FC = () => {
    // const [loading, setLoading] = useState(false);
    // const [backdropVisible, setBackdropVisible] = useState(false);
    const darkModeValue = localStorage.getItem("darkMode");
    const newUser = localStorage.getItem("newUser");

    const history = useHistory();

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');
    const [notificationTap, setNotificationTap] = useState('');
    const [bookingId, setBookingId] = useState('');

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);
    const [networkStatus, setNetworkStatus] = useState<any>(true);

    function getPlatforms() {
        // Simple check for desktop or mobile platform
        if (navigator.userAgent.indexOf('Mobi') > -1) {
            return "mobile";
        } else {
            return "desktop";
        }
    }

    useEffect(() => {

        // This is FCM vapidKey        
        // BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI

        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
        });

        // for pwa only

        if (getPlatforms() == "desktop") {
            isSupported().then((supported) => {
                console.log('Messaging support check:', supported);
                if (supported) {
                    try {
                        console.log('Firebase Messaging initialized:', messaging);
                        // Request permission to send notifications
                        Notification.requestPermission().then(permission => {
                            if (permission === "granted") {
                                // Get and log the token
                                getToken(messaging, { vapidKey: 'BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI' })
                                    .then((currentToken) => {
                                        if (currentToken) {
                                            console.log("FCM Token:", currentToken);
                                            localStorage.setItem("fcmToken", currentToken);
                                        } else {
                                            console.log("No registration token available. Request permission to generate one.");
                                        }
                                    })
                                    .catch((error) => {
                                        console.error('An error occurred while retrieving token. ', error);
                                    });
                            } else {
                                console.error("Permission denied for notifications");
                                localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
                            }
                        });
                    } catch (error) {
                        console.error('Failed to initialize Firebase Messaging:', error);
                    }
                }
            }).catch((error) => {
                console.error('Error during Firebase Messaging support check:', error);
                // localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
            });
        } else {


            PushNotifications.register();
            const handlePushNotification = (notification: any) => {
                // Access the notification payload, including the image URL
                const { title, body, data } = notification;

                // Check if the notification contains an image URL
                const imageUrl = data?.image || null;

                // Handle the image display and notification content
                if (imageUrl) {
                    // Image is present, handle image display (e.g., using an image component)
                    console.log('Image URL:', imageUrl);
                    // Implement your image display logic here, e.g., with an <img> element
                } else {
                    // Image is not present, handle notification content without an image
                    console.log('No Image URL provided');
                }

                // Handle other notification data or actions as needed
                console.log('Received push notification:', title, body, data);
            };

            // Add the pushNotificationReceived listener
            const pushNotificationReceivedListener = PushNotifications.addListener(
                'pushNotificationReceived',
                handlePushNotification
            );
            addListeners();
            // Return a cleanup function to remove the listener when the component unmounts
            return () => {
                pushNotificationReceivedListener.remove();
            };
        }

    }, []);


    // const checkPartner = () => {
    //     axios.post(`${apiConfig.baseUrl}/checkpartner`, {
    //         "bookingId": "214361",
    //         "token": localStorage.getItem("fcmToken"),
    //     })
    //         .then(response => {
    //             console.log("response", response);
    //             if (response.data.suspendstatus == false) {
    //                 setSupendedMsg(response.data.message);
    //                 setSupendedTitle(response.data.title);
    //                 setShowSuspendedModal(true);
    //             } else {

    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    // const notificationRoute = () => {
    //     console.log("localStorage.getItem---------->>>", localStorage.getItem("bookingId"));
    //     axios.post(`${apiConfig.baseUrl}/ViewBookingList`, {
    //         "bookingId": localStorage.getItem("bookingId"),
    //         "token": localStorage.getItem("fcmToken"),
    //         "status": "2"
    //     })
    //         .then(response => {
    //             if (response.data.suspendstatus == false) {
    //                 setSupendedMsg(response.data.message);
    //                 setSupendedTitle(response.data.title);
    //                 setShowSuspendedModal(true);
    //             } else {
    //                 if (response.data.data[0].status == 8 || response.data.data[0].status == 5 || response.data.data[0].status == 7 || response.data.data[0].status == 1 || response.data.data[0].lead_quote_details == null) {
    //                     setNotificationRoute('wrong');
    //                     console.log('----------->>>>Wrong route');
    //                 } else {
    //                     setNotificationRoute('correct');
    //                     console.log('----------->>>Correct route');
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }
    const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
            console.info('Registration token: ', token.value);
            localStorage.setItem("fcmToken", token.value);
        })

        await PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
        });
        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received: ', notification.body);
            const notifyBody = notification.body;
            const idMatch = notifyBody?.match(/ID: #(\d+)/);
            if (idMatch) {
                const id = idMatch[1]; // Get the first capturing group
                console.log('Extracted ID: ', id);

                // Debugging statements to check the flow
                localStorage.setItem("bookingId", id);
                console.log('localStorage updated with ID: ', localStorage.getItem("bookingId"));

                setBookingId(id);
                console.log('Booking ID set: ', id);

                // notificationRoute();
            } else {
                console.log('ID not found in the notification body.');
                localStorage.setItem("bookingId", "");
            }
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            console.log('Push notification action performed', notification);
            if (notification.actionId === "tap") {
                setNotificationTap(notification.actionId);
                if (!bookingId) {
                    // if (notifyRoute === 'correct') {
                    // history.push('/viewbooking');
                    //     console.log("correct viewbooking --------->>>");
                    // } else {
                    //     console.log("wrong notifications --------->>>");
                    history.push('/notifications');
                    // }
                } else {
                    console.log("avail id notifications --------->>>");
                    history.push('/viewbooking');
                }
            }
        });

    }

    useIonViewWillEnter(() => {
        // firebase.initializeApp(firebaseConfig);
        // async function fetchToken() {
        //     try {
        //         const { token } = await FCM.getToken();
        //         console.log("FCM Token -------->>>>", token);
        //         localStorage.setItem("fcmToken", token);
        //         if (localStorage.getItem("fcmToken") != "") {
        //             handShake();
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
        // fetchToken();
        if (newUser == "") {
            localStorage.setItem("newUser", "no");
        } else {
            localStorage.setItem("newUser", "yes");
        }
        if (darkModeValue == "darkMode") {
            localStorage.setItem("darkMode", "darkMode");
        } else if (darkModeValue == "") {
            localStorage.setItem("darkMode", "lightMode");
        } else {
            localStorage.setItem("darkMode", "lightMode");
        }
        // localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
        handShake();
        // checkPartner();
    }, []);
    const handShake = () => {
        axios.post(`${apiConfig.baseUrl}/handshake`, {
            token: localStorage.getItem("fcmToken")
        })
            .then(response => {
                console.log("hand shake response", response.data);
                if (response.data.suspendstatus == false) {
                    setSupendedMsg(response.data.message);
                    setSupendedTitle(response.data.title);
                    setShowSuspendedModal(true);
                } else {
                    if (newUser == "yes") {
                        if (response.data.status == false || response.data.data.remember_me == 0) {
                            history.push('/login');
                        } else {
                            localStorage.setItem("name", response.data.data.name);
                            localStorage.setItem("tradingName", response.data.data.ownername);
                            localStorage.setItem("email", response.data.data.email);
                            localStorage.setItem("mobile", response.data.data.mobile);
                            localStorage.setItem("postcode", response.data.data.postcode);
                            unlockAppWithFingerprint();
                        }
                    } else {
                        history.push('/intro');
                    }
                }
            })
            .catch((error: any) => {
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
            });
    }
    async function unlockAppWithFingerprint() {
        try {
            await fingerprintService.authenticate()
                .then((result_1: any) => {
                    console.log('Inside success', result_1);
                    if (notificationTap === 'tap') {
                        if (!bookingId) {
                            // if (notifyRoute === 'correct') {
                            // history.push('/viewbooking');
                            //     console.log("correct viewbooking --------->>>");
                            // } else {
                            //     console.log("wrong notifications --------->>>");
                            history.push('/notifications');
                            // }
                        } else {
                            console.log("avail id notifications --------->>>");
                            history.push('/viewbooking');
                        }
                    } else {
                        history.push('/tabs/dashboard');
                    }
                })
                .catch((error: any) => {
                    console.log("error--->>Fingerprint", error);
                    if (error) {
                        history.push('/login');
                    }
                    // Handle the error
                });
        } catch (error) {
            console.error('Error:', error);
            // Handle any errors that occurred during authentication
        }
    }
    return (
        <IonPage>
            {networkStatus == false &&
                <div className="no-network-div">
                    <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                    <h6 className='fontName'>Check your internet connection and try again</h6>
                </div>
            }
            {networkStatus == true &&
                <IonContent className="ion-padding overflow background-img" fullscreen>
                    <div className="fontName background-img-container">
                        <img src="assets/imgs/images/setStartedBg.png" alt="Background Image" />
                    </div>
                    <IonGrid>
                        <IonRow>
                            <IonCol col-12 size="12">
                                <IonImg className="afh-logo" src="assets/imgs/images/afhLogo.svg" alt="Logo Image"></IonImg>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <div className="m-title getstarted-title">
                        <IonLabel className="getstarted-msg">Welcome to Anything for Hire Partner Centre.</IonLabel>
                        <br />
                        <IonLabel className="letsgrow-msg">Manage your bookings and services on the go!</IonLabel>
                    </div>
                    {/* <IonGrid>
                    <IonRow>
                        <IonCol col-12 size="12">
                            <IonButton expand="block" className="getstarted-btn" routerLink="/intro">
                                Get Started
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid> */}
                    <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
                        <IonContent className="model-bg">
                            <IonGrid className='cnfmModelGrid'>
                                <IonRow>
                                    <IonCol size="12" col-12>
                                        <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                    </IonCol>
                                    <IonCol size="12" col-12>
                                        <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                    </IonCol>
                                    <IonCol size="12" col-12>
                                        <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                    </IonCol>
                                    <IonCol size='12' col-12>
                                        <IonButton
                                            routerLink="/login"
                                            routerDirection="none"
                                            style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                                            Back to Login
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonContent>
                    </IonModal>
                </IonContent>
            }
        </IonPage>
    );
};

export default Getstarted;
