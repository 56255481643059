import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import axios from "axios";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import apiConfig from "../../apiConfig";
import { App as CapacitorApp } from '@capacitor/app';
import "./Login.css";

import { Network } from '@capacitor/network';

import messaging from '../../Firebase';
import { getToken, isSupported, onMessage } from 'firebase/messaging';

import { getPlatforms } from '@ionic/react';

const Login: React.FC = () => {
  const history = useHistory();
  const darkModeValue = localStorage.getItem("darkMode");
  const [showBackAlert, setShowBackAlert] = useState(false);
  const [rememberStatus, setRememberStatus] = useState(Number);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
  });
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [iserror, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [inputEmail, setInputEmail] = useState('');
  const latestInputEmailValue = useRef('');
  const latestInputPasswordValue = useRef('');
  const [inputPassword, setInputPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  function getPlatforms() {
    // Simple check for desktop or mobile platform
    if (navigator.userAgent.indexOf('Mobi') > -1) {
      return "mobile";
    } else {
      return "desktop";
    }
  }
  useEffect(() => {


    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });


    // for pwa only

    // requestNotificationPermission();


    // if (getPlatforms() == "desktop") {
    //   isSupported().then((supported) => {
    //     console.log('Messaging support check:', supported);
    //     if (supported) {
    //       try {
    //         console.log('Firebase Messaging initialized:', messaging);
    //         // Request permission to send notifications
    //         Notification.requestPermission().then(permission => {
    //           if (permission === "granted") {
    //             // Get and log the token
    //             getToken(messaging, { vapidKey: 'BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI' })
    //               .then((currentToken) => {
    //                 if (currentToken) {
    //                   console.log("FCM Token:", currentToken);
    //                   localStorage.setItem("fcmToken", currentToken);
    //                 } else {
    //                   console.log("No registration token available. Request permission to generate one.");
    //                 }
    //               })
    //               .catch((error) => {
    //                 console.error('An error occurred while retrieving token. ', error);
    //               });
    //           } else {
    //             console.error("Permission denied for notifications");
    //           }
    //         });
    //       } catch (error) {
    //         console.error('Failed to initialize Firebase Messaging:', error);
    //       }
    //     }
    //   }).catch((error) => {
    //     console.error('Error during Firebase Messaging support check:', error);
    //   });
    // }




  }, []);

   // for pwa only

  // const requestNotificationPermission = () => {
  //   Notification.requestPermission().then(permission => {
  //     if (permission === "granted") {
  //       console.log('Notification permission granted.');
  //       // Retry getting the token once permission is granted
  //       getToken(messaging, { vapidKey: 'BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI' })
  //         .then((token) => {
  //           if (token) {
  //             console.log("FCM Token after permission granted:", token);
  //             localStorage.setItem("fcmToken", token);
  //           } else {
  //             console.log("Still no registration token available after permission granted.");
  //             localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error('An error occurred while retrieving token after permission granted:', error);
  //           localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
  //         });
  //     } else {
  //       console.error("Permission denied for notifications");
  //       localStorage.setItem("fcmToken", "cCOSeQQhRtWItUtwpRNrbK:APA91bEVpzc2hAI26SlP4Wt7luCOv2iMsACgNZdcHrKLPEnsXOMlYB8h2M6Y-K699eEaBmMe32XuH9_KOaRjG7cmyNa_VgxRwXQ2Kah9r7J6fRHrLPpZf2wChsAVCCSN_HP3gr");
  //     }
  //   });
  // }


  const handleEmailInputChanges = (event: any) => {
    const email = event.target.value;
    setInputEmail(event.target.value);
    latestInputEmailValue.current = email;
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (email && !emailPattern.test(email)) {
      setFormState(prevState => ({
        ...prevState,
        emailError: 'Invalid email address'
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        emailError: ''
      }));
    }
  };

  const checkboxChanged = (event: any) => {
    console.log('Checkbox state changed:', event.detail.checked);
    if (event.detail.checked == true) {
      setRememberStatus(1);
    } else {
      setRememberStatus(0);
    }
  }


  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    console.log("Login Token", localStorage.getItem("fcmToken"));
    if (formState.emailError == '') {
      if (!latestInputEmailValue.current || !latestInputPasswordValue.current) {
        setErrorMessage('Please enter a valid email and password');
        setShowToast(true);
      } else {
        setLoading(true);
        setBackdropVisible(true);
        const loginData = {
          email: latestInputEmailValue.current,
          password: latestInputPasswordValue.current,
          token: localStorage.getItem("fcmToken"),
          remember_me: 1
        }
        axios.post(`${apiConfig.baseUrl}/login`, loginData)
          .then((res: any) => {
            if (res.data.suspendstatus == false) {
              setSupendedMsg(res.data.message);
              setSupendedTitle(res.data.title);
              setShowSuspendedModal(true);
              setLoading(false);
              setBackdropVisible(false);
            } else {
              if (res.data.status == true) {
                localStorage.setItem("name", res.data.name);
                localStorage.setItem("tradingName", res.data.ownername);
                localStorage.setItem("email", res.data.email);
                localStorage.setItem("displaypicture", res.data.displaypicture);
                localStorage.setItem("mobile", res.data.mobile);
                localStorage.setItem("postcode", res.data.postcode);
                localStorage.setItem("partnerId",res.data.partner_id);
                localStorage.setItem("expensesInfo", "info");
                setLoading(false);
                setBackdropVisible(false);
                setShowPassword(false);
                setInputEmail('');
                setInputPassword('');
                // formState.emailError == '';
                latestInputEmailValue.current = '';
                latestInputPasswordValue.current = '';
                localStorage.setItem("newUser", "yes");
                history.push('/tabs/dashboard');
              } else {
                setLoading(false);
                setBackdropVisible(false);
                setErrorMessage(res.data.message);
                setShowToast(true);
              }
            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch((error) => {
            if (error.code == "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setMessage("Auth failure! Please create an account");
            setIserror(true)
          })

      }
    } else {
      setErrorMessage('Please enter a valid email');
      setShowToast(true);
    }

  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordInputChange = (event: any) => {
    setInputPassword(event.target.value);
    latestInputPasswordValue.current = event.target.value;
  };

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <div className="no-network-div">
          <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
          <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
        </div>
      }
      {networkStatus == true &&
        darkModeValue == "darkMode" &&
        <IonContent className="login ion-padding-horizontal overflow background-img" fullscreen>
          <div className='wrapper background-img-container'>
            <IonImg src="assets/imgs/images/loginBg.png" alt="bg" className="product-image d-d-none"></IonImg>
            <IonImg src="assets/imgs/images/afh-pro-desktop.jpg" alt="bg" className="product-image"></IonImg>
            <div className="dark-card-container">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonImg style={{
                      margin: "1vh 0vh 0vh 1vh"
                    }} className="afh-logo login-logo" src="assets/imgs/images/dark-afhLogo.svg" alt="Logo Image"></IonImg>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="m-title login-title " style={{ margin: "1vh" }}>
                      <IonLabel className="dark-wlcm-title">Welcome Back.</IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <form onSubmit={handleSubmit}>
                <div className="the-form">
                  <div className="input-holder">
                    <IonLabel className="dark-input-label">Email</IonLabel>
                    <IonItem className="dark-login-ion-item input-item" lines="none">
                      <input
                        type="email"
                        name="email"
                        className='dark-fontName dark-input login-input'
                        placeholder="Enter email"
                        value={inputEmail}
                        onChange={e => handleEmailInputChanges(e)}
                        onKeyDown={handleKeyPress}
                      />
                      {/* <IonInput
                    className="form-input"
                    placeholder="Enter Name"
                    type="text"
                  /> */}
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "17px", margin: "0px 10px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/dark-email.svg"
                      />
                    </IonItem>
                  </div>
                  {formState.emailError && <span style={{ marginTop: "-2%" }} className='dark-fontName emailErrorMsg'>{formState.emailError}</span>}
                  <div className="input-holder">
                    <IonLabel className="dark-input-label">Password</IonLabel>
                    <IonItem className="dark-login-ion-item input-item" lines="none">
                      <input
                        type='text'
                        name="password"
                        className={showPassword ? 'dark-input dark-fontName login-input passwordVisible' : 'dark-input dark-fontName login-input passwordHidden'}
                        placeholder="Enter password"
                        value={inputPassword}
                        onChange={handlePasswordInputChange}
                        onKeyDown={handleKeyPress}
                      />
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "20px", margin: "0px 8px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/dark-password.svg"
                      />
                      <IonIcon
                        slot='end'
                        className='dark-eye-icon'
                        onClick={handleTogglePassword}
                        icon={showPassword ? eyeOffOutline : eyeOutline}
                      />
                    </IonItem>
                  </div>
                </div>
                <div className="rem-holder">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="remind-item-div">
                        {/* <IonItem className="rem-item dark-remind-item" lines="none">
                        <IonCheckbox onIonChange={checkboxChanged} className="rem-check" slot="start" mode="ios" />
                        <IonLabel className="dark-rem-label">Remember me</IonLabel>
                      </IonItem> */}
                        <a href='https://partners.anythingforhire.com/password/reset'>
                          <IonLabel className="dark-forgot-password">Forgot Password?</IonLabel>
                        </a>

                      </div>
                    </IonCol>
                  </IonRow>
                </div>

                <div className="btn-holder">
                  <IonButton expand="block" className="login-button" type="submit">
                    Login
                  </IonButton>
                </div>
              </form>
            </div>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={errorMessage}
            duration={3000}
            color="danger"
          />
          <IonLoading
            isOpen={loading}
            spinner="circular"
            translucent={true}
          />
          <IonAlert
            isOpen={showBackAlert}
            onDidDismiss={() => setShowBackAlert(false)}
            header={'Exit App'}
            message={'Are you sure you want to exit the app?'}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  setShowBackAlert(false);
                }
              },
              {
                text: 'Exit',
                handler: () => {
                  CapacitorApp.exitApp();
                }
              }
            ]}
          />
          <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
            <IonContent className="dark-model-bg">
              <IonGrid className='cnfmModelGrid'>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                  </IonCol>
                  <IonCol size='12' col-12>
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }}
                      className='fontName booking-details-btn'
                      onClick={() => setShowSuspendedModal(false)}
                      expand="block">
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <div className="no-network-div">
          <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
          <h6 className='fontName'>Check your internet connection and try again</h6>
        </div>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <IonContent className="login ion-padding-horizontal overflow background-img" fullscreen>
          <div className='wrapper background-img-container'>
            <IonImg src="assets/imgs/images/loginBg.png" alt="bg" className="product-image d-d-none"></IonImg>
            <IonImg src="assets/imgs/images/afh-pro-desktop.jpg" alt="bg" className="product-image"></IonImg>
            <div className="login-card-container">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonImg style={{
                      margin: "1vh 0vh 0vh 1vh"
                    }} className="afh-logo login-logo" src="assets/imgs/images/afhLogo.svg" alt="Logo Image"></IonImg>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="m-title login-title " style={{ margin: "1vh" }}>
                      <IonLabel className="wlcm-title">Welcome Back.</IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <form onSubmit={handleSubmit}>
                <div className="the-form">
                  <div className="input-holder">
                    <IonLabel className="input-label">Email</IonLabel>
                    <IonItem className="login-ion-item input-item" lines="none">
                      <input
                        type="email"
                        name="email"
                        style={{ background: "rgba(255, 255, 255, 0)" }}
                        className='fontName login-input'
                        placeholder="Enter email"
                        value={inputEmail}
                        onChange={e => handleEmailInputChanges(e)}
                        onFocus={(e) => e.preventDefault()}
                      />
                      {/* <IonInput
                    className="form-input"
                    placeholder="Enter Name"
                    type="text"
                  /> */}
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "17px", margin: "0px 10px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/email.svg"
                      />
                    </IonItem>
                  </div>
                  {formState.emailError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.emailError}</span>}
                  <div className="input-holder">
                    <IonLabel className="input-label">Password</IonLabel>
                    <IonItem className="login-ion-item input-item" lines="none">
                      <input
                        type='text'
                        name="password"
                        style={{ background: "rgba(255, 255, 255, 0)" }}
                        className={showPassword ? 'fontName login-input passwordVisible' : 'fontName login-input passwordHidden'}
                        placeholder="Enter password"
                        value={inputPassword}
                        onChange={handlePasswordInputChange}
                        onFocus={(e) => e.preventDefault()}
                      />
                      {/* <IonInput
                    className="form-input"
                    placeholder="Enter Password"
                    type="password"
                  /> */}
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "20px", margin: "0px 8px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/password.svg"
                      />
                      <IonIcon
                        slot='end'
                        className='eye-icon'
                        onClick={handleTogglePassword}
                        icon={showPassword ? eyeOffOutline : eyeOutline}
                      />
                    </IonItem>
                  </div>
                </div>
                <div className="rem-holder">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="remind-item-div">
                        {/* <IonItem className="rem-item remind-item" lines="none">
                        <IonCheckbox onIonChange={checkboxChanged} className="rem-check" slot="start" mode="ios" />
                        <IonLabel className="rem-label">Remember me</IonLabel>
                      </IonItem> */}
                        {/* <IonItem> */}
                        <a href='https://partners.anythingforhire.com/password/reset'>
                          <IonLabel className="forgot-password">Forgot Password?</IonLabel>
                        </a>
                        {/* </IonItem> */}
                      </div>
                    </IonCol>
                  </IonRow>
                </div>

                <div className="btn-holder">
                  <IonButton expand="block" className="login-button" type="submit">
                    Login
                  </IonButton>
                </div>
              </form>
            </div>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={errorMessage}
            duration={3000}
            color="danger"
          />
          <IonLoading
            isOpen={loading}
            spinner="circular"
            translucent={true}
          />
          <IonAlert
            isOpen={showBackAlert}
            onDidDismiss={() => setShowBackAlert(false)}
            header={'Exit App'}
            message={'Are you sure you want to exit the app?'}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  setShowBackAlert(false);
                }
              },
              {
                text: 'Exit',
                handler: () => {
                  CapacitorApp.exitApp();
                }
              }
            ]}
          />
          <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
            <IonContent className="model-bg">
              <IonGrid className='cnfmModelGrid'>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                  </IonCol>
                  <IonCol size='12' col-12>
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      }
    </IonPage >
  );
};

export default Login;
